<template>
  <section>
    <div class="list-second-page">
      <div class="top-msg clearfix">
        <div class="container">
          <div class="row">
            <div class="img-left col-md-6">
              <span class="thumbs">
                <img
                  src="/uploads/image/20210915/10/d-type-solenoid-dosing-pump.jpg"
                  alt="D Type Electromagnetic Solenoid Dosing Pump"
                />
              </span>
            </div>
            <div class="msg-right col-md-6">
              <div class="richtext">
                <p>
                  In the past 20 years, the solenoid driven diaphragm metering
                  pumps are&nbsp;the most popular and typical of NEWDOSE
                  products, offer a wide selection of flow rate and pressure,
                  1~55Lph, 0.1~20Bar. This kind of electromagnetic diphragm
                  dosing pump works powerfully, with high dosing accuracy, and
                  stable. It's convenient to operate and adjust the capacity
                  according to the LED display and simplified buttons.
                  Reasonable and flexible internal design makes customization
                  realizable. We can provide solutions for you according to user
                  demand and special working conditions to avoid the
                  embarrassing modification of process design upon the
                  unsuitability of the product.
                </p>
                <p><br /></p>
              </div>
              <div class="two-btns hide">
                <a href="/uploads/file/2020-newdose-dosing-pump-catalog.pdf">
                  Brochure Download
                </a>
                <a href="/uploads/file/motor-x-motor-driven-metering-pump.pdf">
                  Manual Download
                </a>
              </div>
              <div class="next-links">
                <p>Advantages and Features：</p>
                <ul class="list-paddingleft-2" style="list-style-type: disc">
                  <li><p>Stroke length adjustment</p></li>
                  <li><p>High efficiency delivery</p></li>
                  <li><p>Excellent compact structure</p></li>
                  <li><p>Optimized pump head structure</p></li>
                  <li><p>Ultra-low maintenance cost</p></li>
                  <li><p>Idling to be allowed</p></li>
                  <li><p>Simplify operation</p></li>
                  <li><p>Convenient to manage stocking</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-box">
        <div class="container">
          <div class="row">
            <div class="main-left col-md-9">
              <div class="sole-box">
                <h2>
                  <p
                    class="sole-title"
                    style="margin-bottom: 10px; border-bottom: none"
                  >
                    D Type Solenoid Dosing Pump List
                  </p>
                </h2>
                <ul class="s-list">
                  <li class="col-md-6" style="padding: 20px">
                    <div class="part">
                      <div class="thumb">
                        <a href="/products/dfd-type-solenoid-dosing-pump.html"
                          ><img
                            src="/uploads/image/20201118/10/dfd-type-solenoid-dosing-pump1596804520.jpg"
                            alt="DFD Type Solenoid Dosing Pump"
                            class="sep-scale"
                        /></a>
                      </div>
                      <a
                        href="/products/dfd-type-solenoid-dosing-pump.html"
                        class="title"
                      >
                        DFD Type Solenoid Dosing Pump
                      </a>
                      <div class="tips">Manual Operation</div>
                    </div>
                  </li>
                  <li class="col-md-6" style="padding: 20px">
                    <div class="part">
                      <div class="thumb">
                        <a href="/products/dp-type-solenoid-dosing-pump.html"
                          ><img
                            src="/uploads/image/20200807/20/dp-type-solenoid-dosing-pump.jpg"
                            alt="DP Type Solenoid Dosing Pump"
                            class="sep-scale"
                        /></a>
                      </div>
                      <a
                        href="/products/dp-type-solenoid-dosing-pump.html"
                        class="title"
                      >
                        DP Type Solenoid Dosing Pump
                      </a>
                      <div class="tips">Pulse Signal</div>
                    </div>
                  </li>
                  <li class="col-md-6" style="padding: 20px">
                    <div class="part">
                      <div class="thumb">
                        <a href="/products/dm-type-solenoid-dosing-pump.html"
                          ><img
                            src="/uploads/image/20200807/20/dm-type-solenoid-dosing-pump.jpg"
                            alt="DM Type Solenoid Dosing Pump"
                            class="sep-scale"
                        /></a>
                      </div>
                      <a
                        href="/products/dm-type-solenoid-dosing-pump.html"
                        class="title"
                      >
                        DM Type Solenoid Dosing Pump
                      </a>
                      <div class="tips">4-20mA</div>
                    </div>
                  </li>
                  <li class="col-md-6" style="padding: 20px">
                    <div class="part">
                      <div class="thumb">
                        <a href="/products/dc-type-solenoid-dosing-pump.html"
                          ><img
                            src="/uploads/image/20200807/20/dc-type-solenoid-dosing-pump.jpg"
                            alt="DC Type Solenoid Dosing Pump"
                            class="sep-scale"
                        /></a>
                      </div>
                      <a
                        href="/products/dc-type-solenoid-dosing-pump.html"
                        class="title"
                      >
                        DC Type Solenoid Dosing Pump
                      </a>
                      <div class="tips">RS485</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="bk-box">
                <h2 class="h2-title">
                  D Type Electromagnetic Solenoid Dosing Pump Overview
                </h2>

                <div class="disflex1 clearfix">
                  <div class="col-md-6 p-left">
                    <img
                      src="/uploads/image/20210706/16/solenoid-pump.png"
                      alt="D Type Electromagnetic Solenoid Dosing Pump Flow Rate"
                    />
                  </div>
                  <div class="col-md-6 p-right">
                    <div class="richtext">
                      <ul
                        class="list-paddingleft-2"
                        style="list-style-type: disc"
                      >
                        <li>
                          <p><strong>Flow rate:&nbsp;</strong>0-55Lph&nbsp;</p>
                        </li>
                        <li>
                          <p>
                            <strong>Maximum Pressure: 0-</strong>20Bar&nbsp;
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Material of Pump Head:&nbsp;</strong>PP
                            (anti-corrosion)&nbsp;
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Optional Material:&nbsp;</strong>PVC, PTFE,
                            SS316
                          </p>
                        </li>
                      </ul>
                      <p><br /></p>
                    </div>
                  </div>
                </div>

                <div class="disflex2 clearfix">
                  <div class="col-md-6 p-left">
                    <img
                      src="/uploads/image/20210706/17/flow-rate-pressure.jpg"
                      alt="D Type Electromagnetic Solenoid Dosing Pump Package Contents"
                      class="sep-scale"
                    />
                  </div>
                  <div class="col-md-6 p-right">
                    <div class="richtext">
                      <p><strong>Installation Kits:</strong></p>
                      <ul
                        class="list-paddingleft-2"
                        style="list-style-type: disc"
                      >
                        <li><p>Suction and delivery pipes</p></li>
                        <li><p>Three-piece connectors</p></li>
                        <li><p>Filter</p></li>
                        <li><p>Sprayer</p></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-right col-md-3">
              <div class="slider-box">
                <p class="s-title">
                  <img
                    src="https://www.newdosepump.com/themes/simple/img/slider.png"
                    alt="DOSING PUMPS"
                  />
                  <a href="/products/" title="DOSING PUMPS">DOSING PUMPS</a>
                </p>
                <ul class="level-first-box">
                  <li class="active">
                    <a
                      href="/products/d-type-electromagnetic-solenoid-dosing-pump/"
                      title="Solenoid Dosing Pump"
                      >Solenoid Dosing Pump</a
                    >
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <ul>
                      <li class="active">
                        <a
                          href="/products/d-type-electromagnetic-solenoid-dosing-pump/"
                          title="D Type Electromagnetic Solenoid Dosing Pump"
                          >D Type Electromagnetic Solenoid Dosing Pump</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/motor-dosing-pump/"
                      title="Motor Driven Diaphragm Pump"
                      >Motor Driven Diaphragm Pump</a
                    >
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <ul>
                      <li class="">
                        <a
                          href="/products/motor-x-micro-mechanical-motor-dosing-pump.html"
                          title="Motor X Micro-mechanical Motor Dosing Pump"
                          >Motor X Micro-mechanical Motor Dosing Pump</a
                        >
                      </li>
                      <li class="">
                        <a
                          href="/products/nd-type-mechanical-motor-dosing-pump/"
                          title="ND Type Mechanical Motor Dosing Pump"
                          >ND Type Mechanical Motor Dosing Pump</a
                        >
                      </li>
                      <li class="">
                        <a
                          href="/products/ecodose-type-mechanical-motor-dosing-pump.html"
                          title="Ecodose Type Mechanical Motor Dosing Pump"
                          >Ecodose Type Mechanical Motor Dosing Pump</a
                        >
                      </li>
                      <li class="">
                        <a
                          href="/products/nd-electric-stroke-adjuster.html"
                          title="ND Electric Stroke Adjuster"
                          >ND Electric Stroke Adjuster</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="">
                    <a href="/products/metering-pump.html" title="Metering Pump"
                      >Metering Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/diaphragm-pump.html"
                      title="Diaphragm Pump"
                      >Diaphragm Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/low-pressure-dosing-pump.html"
                      title="Low Pressure Dosing Pump"
                      >Low Pressure Dosing Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/high-pressure-dosing-pump.html"
                      title="High Pressure Dosing Pump"
                      >High Pressure Dosing Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/manual-dosing-pump.html"
                      title="Manual Dosing Pump"
                      >Manual Dosing Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/water-dosing-pump.html"
                      title="Water Dosing Pump"
                      >Water Dosing Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/detergent-dosing-pump.html"
                      title="Detergent Dosing Pump"
                      >Detergent Dosing Pump</a
                    >
                    <ul></ul>
                  </li>
                  <li class="">
                    <a
                      href="/products/mechanical-dosing-pump.html"
                      title="Mechanical Dosing Pump"
                      >Mechanical Dosing Pump</a
                    >
                    <ul></ul>
                  </li>
                </ul>
              </div>
              <div class="contact-bar">
                <a href="/contact.html">
                  <span class="icons">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-zixun"></use>
                    </svg>
                  </span>
                  <em>Online Consultation</em>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-box">
        <div class="container">
          <div class="row">
            <div class="f-left col-md-6">
              <div class="inbox">
                <p class="f-title">
                  Tell Us Your Needs for Dosing Pumps，<span>NEWDOSE</span> team
                  Get Back to You ASAP!
                </p>
                <ul class="c-list wow fadeInRight animated">
                  <li>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    <span
                      >Block A-903, CATIC Plaza, No.15 Ronghua South Road,
                      Daxing District, Beijing, China. (100176)</span
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      <span>86-17600697421</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:ark-company@newdose.net<br>oscar.yao@newdose.net"
                    >
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      <span
                        >ark-company@newdose.net<br />oscar.yao@newdose.net</span
                      >
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-faq-box">
        <div class="container">
          <div class="row">
            <h2>
              <p class="faq-title">
                FAQs About Solenoid Driven Diaphragm Metering Pumps
              </p>
            </h2>
            <ul class="faq-list">
              <li class="">
                <h3 class="question">
                  What's the max capacity of the small solenoid metering pump?
                  <span class="icons hide">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </h3>

                <div class="answer">
                  <p>
                    The max flow rate is 55Lph when the pressure is very low,
                    lower than 0.1Bar.
                  </p>
                </div>
              </li>
              <li class="">
                <h3 class="question">
                  What's the main usage of the sprayer?
                  <span class="icons hide">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </h3>

                <div class="answer">
                  <p>
                    The sprayer is used to connect the outlet pipe and the
                    injection point, but only for the solenoid driven dosing
                    pump. It can make the liquid be sprayed like mist and
                    injected well-distributed.&nbsp;It should not be used as an
                    isolating device or as a protection against siphonage.
                  </p>
                </div>
              </li>
              <li class="">
                <h3 class="question">
                  How to change the diaphragm of solenoid dosing pump?
                  <span class="icons hide">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </h3>

                <div class="answer">
                  <ul class="list-paddingleft-2" style="list-style-type: disc">
                    <li>
                      <p>
                        Remove the screws on the back of the pump which fixes
                        the pump head.
                      </p>
                    </li>
                    <li>
                      <p>
                        Take down the pump head, and unscrew the diaphragm with
                        a little strength.
                      </p>
                    </li>
                    <li>
                      <p>
                        Once the diaphragm is taken down, check if there any
                        wear or damages on its surface.
                      </p>
                    </li>
                    <li>
                      <p>
                        Then change a new diaphragm to replace, and screw the
                        pump head back to the pump, make sure the check valves
                        is perpendicular to the ground.
                      </p>
                    </li>
                  </ul>
                  <p><br /></p>
                </div>
              </li>
              <li class="">
                <h3 class="question">
                  Can the solenoid pump be dry running?
                  <span class="icons hide">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </h3>

                <div class="answer">
                  <p>
                    Newdose solenoid pump can be dry running less than 12 hours.
                    Even though we suggest the customer install the warning
                    equipment such as a level switch. Or the long-time dry
                    running will ruin the pumps.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="related-news" style="padding: 90px 0 0 0">
        <div class="container">
          <div class="row">
            <p class="n-title"></p>
            <ul>
              <li class="col-md-4">
                <div class="part">
                  <a
                    class="thumb"
                    href="/video/electrical-wiring-of-solenoid-metering-pump.html"
                  >
                    <img
                      src="/uploads/image/20200811/17/electrical-wiring-of-solenoid-pump.jpg"
                      alt="Electrical Wiring of Solenoid Metering Pump"
                      class="sep-scale"
                    />
                  </a>
                  <div class="ibox">
                    <a
                      href="/video/electrical-wiring-of-solenoid-metering-pump.html"
                      class="title"
                      >Electrical Wiring of Solenoid Metering Pump</a
                    >
                  </div>
                </div>
              </li>
              <li class="col-md-4">
                <div class="part">
                  <a
                    class="thumb"
                    href="/video/flow-calibration-of-solenoid-metering-pump.html"
                  >
                    <img
                      src="/uploads/image/20200811/17/flow-calibration-of-solenoid-pump.jpg"
                      alt="Flow Calibration of Solenoid Metering Pump"
                      class="sep-scale"
                    />
                  </a>
                  <div class="ibox">
                    <a
                      href="/video/flow-calibration-of-solenoid-metering-pump.html"
                      class="title"
                      >Flow Calibration of Solenoid Metering Pump</a
                    >
                  </div>
                </div>
              </li>
              <li class="col-md-4">
                <div class="part">
                  <a
                    class="thumb"
                    href="/video/installation-of-solenoid-metering-pump.html"
                  >
                    <img
                      src="/uploads/image/20200811/17/installation-of-solenoid-pump_1597139718.jpg"
                      alt="Installation of Solenoid Metering Pump"
                      class="sep-scale"
                    />
                  </a>
                  <div class="ibox">
                    <a
                      href="/video/installation-of-solenoid-metering-pump.html"
                      class="title"
                      >Installation of Solenoid Metering Pump</a
                    >
                  </div>
                </div>
              </li>
              <li class="col-md-4">
                <div class="part">
                  <a
                    class="thumb"
                    href="/video/the-common-troubleshooting-of-solenoid-metering-pump.html"
                  >
                    <img
                      src="/uploads/image/20200811/17/the-commeon-troubleshouting-of-solenoid-pump.jpg"
                      alt="The Common Troubleshooting of Solenoid Metering Pump"
                      class="sep-scale"
                    />
                  </a>
                  <div class="ibox">
                    <a
                      href="/video/the-common-troubleshooting-of-solenoid-metering-pump.html"
                      class="title"
                      >The Common Troubleshooting of Solenoid Metering Pump</a
                    >
                  </div>
                </div>
              </li>
              <li class="col-md-4">
                <div class="part">
                  <a
                    class="thumb"
                    href="/video/the-way-of-hard-tube-connecting-with-solenoid-metering-pump.html"
                  >
                    <img
                      src="/uploads/image/20200811/17/the-way-of-hard-tube-connecting-with-solenoid-pump.jpg"
                      alt="The Way of Hard Tube Connecting with Solenoid Metering Pump"
                      class="sep-scale"
                    />
                  </a>
                  <div class="ibox">
                    <a
                      href="/video/the-way-of-hard-tube-connecting-with-solenoid-metering-pump.html"
                      class="title"
                      >The Way of Hard Tube Connecting with Solenoid Metering
                      Pump</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="related-news" style="padding: 90px 0">
        <div class="container">
          <div class="row">
            <p class="n-title">Related Articles</p>
            <ul>
              <li class="col-md-4">
                <div class="part">
                  <a
                    href="/the-basic-knowledge-of-metered-dose-pump-and-its-characteristics.html"
                    class="thumb"
                  >
                    <img
                      src="/uploads/image/20200808/11/related-articles-1.jpg"
                      alt="The Basic Knowledge of Metered Dose Pump and Its Characteristics"
                    />
                  </a>
                  <p class="date">17 , Nov, 2021</p>
                  <a
                    href="/the-basic-knowledge-of-metered-dose-pump-and-its-characteristics.html"
                    class="title"
                  >
                    The Basic Knowledge of Metered Dose Pump and Its
                    Characteristics
                  </a>
                </div>
              </li>
              <li class="col-md-4">
                <div class="part">
                  <a
                    href="/what-are-the-uses-of-automatic-dosing-devices.html"
                    class="thumb"
                  >
                    <img
                      src="/uploads/image/20200808/11/related-articles-1_1596857946.jpg"
                      alt="What Are the Uses of Automatic Dosing Units?"
                    />
                  </a>
                  <p class="date">03 , Nov, 2021</p>
                  <a
                    href="/what-are-the-uses-of-automatic-dosing-devices.html"
                    class="title"
                  >
                    What Are the Uses of Automatic Dosing Units?
                  </a>
                </div>
              </li>
              <li class="col-md-4">
                <div class="part">
                  <a
                    href="/definition-and-introduction-of-metered-dose-pump.html"
                    class="thumb"
                  >
                    <img
                      src="/uploads/image/20200808/11/related-articles-3.jpg"
                      alt="Definition and Introduction of Metered Dose Pump"
                    />
                  </a>
                  <p class="date">25 , Oct, 2021</p>
                  <a
                    href="/definition-and-introduction-of-metered-dose-pump.html"
                    class="title"
                  >
                    Definition and Introduction of Metered Dose Pump
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  title: "Solenoid Dosing Pump",
}
</script>